import React, { useEffect, useState } from 'react';
import { checkStatus } from '../helpers';
import CompanyDetailBlock from './CompanyDetailBlock';
import FinancialDataBlock from './FinancialDataBlock';
import DocumentsBlock from './DocumentsBlock';
import { connect } from 'react-redux';
import "./CompanyDetail.css";
import LimitationBanner from '../UiRestrictions/LimitationBanner';
import { Helmet } from "react-helmet";
import RobotsBanner from '../UiRestrictions/RobotsBanner';
import DocumentsStats from './DocumentsStats';
import CustomDeletedIcon from '../Components/DeletedIcon';
import Skeleton from '@mui/material/Skeleton';
import MetricsBlock from './MetricsBlock';
import SimilarCompanies from './SimilarCompanies';
import { showLimitError } from '../Limiter/limiterActions';
import SimilarCompaniesBlock from './SimilarCompaniesBlock';
import BusinessCategoriesBlock from './BusinessCategoriesBlock';
import { Tabs, Tab } from 'react-bootstrap';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import { cropString } from '../helpers';
import { CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BigHorizontalBanner from '../Adverts/BigHorizontalBanner';
import ErrorLimitationBanner from '../Limiter/LimitsExceededBanner';
import LeaderBoardBanner from '../Adverts/LeadBoardBanner';
import SidePannelBanner from '../Adverts/SidePannelBanner';
import { generateAdContext } from '../Adverts/adsHelper';
import MobilePopUp from '../Adverts/MobilePopUp';

const CompanyDetail = (props) => {

    const [company, setCompany] = useState(null);
    const [documents, setDocuments] = useState(null);
    const [financialData, setFinancialData] = useState(null);
    const [businessCodes, setBusinessCodes] = useState(null);
    const [similarCompanies, setSimilarCompanies] = useState(null);

    useEffect(() => {

        getCompanyData(props.match.params.id, props.token).then(company =>
            setCompany(company)
        ).catch(error => props.dispatchError())

        if (props.user) {
            getFinancialData(props.match.params.id, props.token)
                .then(financialData => setFinancialData(financialData))
                .catch(error => props.dispatchError())
        }
        else {
            getFinancialData(props.match.params.id, props.token, true)
                .then(financialData => setFinancialData(financialData))
                .catch(error => props.dispatchError())
        }

        getBusinessCategoriesOverApi(props.match.params.id, props.token).then((response) => setBusinessCodes(response.results))
            .catch(error => props.dispatchError())
        getDocuments(props.match.params.id, props.token).then(documents => setDocuments(documents))
            .catch(error => props.dispatchError())
        getSimilarCompaniesOverApi(props.match.params.id, props.token).then((response) => setSimilarCompanies(response.results))
            .catch(error => props.dispatchError())

    }, [])


    const leaveCompanyDetail = () => {

        if (props.history.location.key) {
            props.history.goBack()
        }
        else {
            props.history.push("/")
        }
    }


    let metadata;
    let publicId;
    let title;
    let canonicalUrl;
    let slug;
    if (!company) {

        slug = window.location.href.split("/").pop()
        publicId = slug.includes('-') ? slug.split("-")[0] : "";
        canonicalUrl = window.location.href.replace("https://www.", "https://");
        title = slug.includes('-') ? slug.split("-").slice(1).map(name => name[0].toUpperCase() + name.slice(1)).join(" ") : "";

        title = title.replace(/ Sro$/, ' s.r.o.')
        title = title.replace(/ As$/, ' a.s.')

        const generatedContent = publicId ? `${title ? title + " ; " : ''}IČO:${publicId} ; URL: ${canonicalUrl}.` : `Detail firmy obsahující učetní uzávěrky firmy. URL: ${canonicalUrl}.`

        metadata = <Helmet>
            <title>{title}</title>
            <meta name="description" content={generatedContent} />
            <meta name="og:description" content={generatedContent} />
            <meta name="og:title" content={title} />
            <meta property="og:type" content="website" />
            <link href={canonicalUrl} rel="canonical"></link>
            {publicId ? null : <meta name="robots" content="noindex" />}
        </Helmet>
    } else {
        canonicalUrl = window.location.href.replace("https://www.", "https://");
        const generatedContent = `Název: ${company.name} ; IČO: ${company.public_id} ; Adresa: ${company.address} ; URL: ${canonicalUrl}.`
        title = `${company.name} - IČO:${company.public_id}, ${cropString(company.address, 30)}`
        slug = window.location.href.split("/").pop()
        publicId = slug.includes('-') ? slug.split("-")[0] : "";

        metadata = <Helmet>
            <title>{title}</title>
            <meta name="description" content={generatedContent} />
            <meta name="og:description" content={generatedContent} />
            <meta name="og:title" content={title} />
            <meta property="og:type" content="website" />
            <link href={canonicalUrl} rel="canonical"></link>
            {publicId ? null : <meta name="robots" content="noindex" />}
        </Helmet>
    }

    const [adContext] = useState(generateAdContext(window.location.pathname, window.location.href.split("/").pop()));

    return (

        <div className="mainAndAdsContent">

            <SidePannelBanner
                context={adContext}
                zoneId={372553}
                id="ssp-zone-372553"
            />


            <div className='companyDetail'>

                <LeaderBoardBanner
                    context={adContext}
                    zoneId={372550}
                    id="ssp-zone-372550"
                />

                <div className='companyDetailMain'>

                    <div className="verticalNavigation">
                        <div className="navigationBackDiv" onClick={leaveCompanyDetail}>
                            <ArrowBackIcon fontSize="large" />
                        </div>
                    </div>
                    {metadata}

                    <div className="companyDetailRight">
                        <LimitationBanner />

                        {props.showLimitError ? <ErrorLimitationBanner /> : null}

                        {company ?
                            <div className='companyHeading'>
                                <div className='companyNameDiv'>
                                    {company.deleted_at ?
                                        <CustomDeletedIcon locationType="detail" deletedAt={company.deleted_at} />
                                        : null}
                                    <h1>{company.name}</h1>
                                </div>
                                <RobotsBanner />
                            </div> : <div className="loadingPlaceholder">
                                <h1>{title}</h1>
                                <CircularProgress />
                            </div>
                        }

                        <Tabs
                            defaultActiveKey="detail"
                            id="uncontrolled-tab-example"
                            className="mb-3 tabsMenu"
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >

                            <Tab eventKey="detail" title={<span><InfoIcon style={{ color: `var(--outline)` }} /> Detail</span>}>

                                {company ?
                                    <CompanyDetailBlock
                                        name={company.name}
                                        id={company.public_id}
                                        address={company.address}
                                        url={canonicalUrl}
                                        slug={company.web_slug}
                                        createdAt={company.created_at}
                                        deletedAt={company.deleted_at}
                                        legalForm={company.legal_form}
                                        businessType={company.business_type}
                                        activityType={company.activity_type}
                                        fileId={company.file_id}
                                        user={props.user}
                                        orCompanyUrl={company.metadata.sources.or_company_detail}
                                        equityCapital={company.equity_capital}
                                    />
                                    : <div>
                                        <CompanyDetailBlock
                                            name={title}
                                            id={publicId}
                                            url={canonicalUrl}
                                            slug={slug}
                                        />
                                        <div className='documentsSkeleton'>
                                            <Skeleton variant="rounded" height={300} />
                                        </div>
                                    </div>}

                                {company && financialData ?
                                    <MetricsBlock yearData={financialData} user={props.user} />
                                    : <div className='documentsSkeleton'>
                                        <Skeleton variant="rounded" height={300} />
                                    </div>}

                                <BigHorizontalBanner
                                    context={adContext}
                                    zoneId={355933}
                                    id="ssp-zone-355933"
                                />

                                {documents ?
                                    <DocumentsStats documents={documents} /> :
                                    <div className='documentsSkeleton'>
                                        <Skeleton variant="rounded" height={300} />
                                    </div>
                                }

                                {similarCompanies ?
                                    <SimilarCompaniesBlock companies={similarCompanies.filter(c => c.public_id != publicId)} /> :
                                    <div className='documentsSkeleton'>
                                        <Skeleton variant="rounded" height={300} />
                                    </div>
                                }

                                {businessCodes ?
                                    <BusinessCategoriesBlock businessCategories={businessCodes} /> :
                                    <div className='documentsSkeleton'>
                                        <Skeleton variant="rounded" height={300} />
                                    </div>
                                }
                            </Tab>

                            <Tab eventKey="financial_state" title={<span><PriceChangeIcon style={{ color: `var(--outline)` }} /> Finanční stav</span>}>

                                    <MobilePopUp
                                        id="ssp-zone-373828"
                                        zoneId={373828}
                                    />

                                {company && financialData ?
                                    <FinancialDataBlock
                                        company={company.name}
                                        financialData={financialData}
                                        user={props.user}
                                        orDocumentsUrl={company.metadata.sources.or_documents_list}
                                    /> : null}
                            </Tab>

                            <Tab eventKey="documents" title={<span><DescriptionIcon style={{ color: `var(--outline)` }} /> Učetní závěrky</span>}>

                                {documents && company ?
                                    <DocumentsBlock
                                        documents={documents}
                                        user={props.user}
                                        orDocumentsUrl={company.metadata.sources.or_documents_list}
                                    />
                                    : null}

                            </Tab>

                            <Tab eventKey="similar_entities" title={<span><JoinLeftIcon style={{ color: `var(--outline)` }} /> Podobné subjekty</span>}>

                                {company && similarCompanies ?
                                    <SimilarCompanies companies={similarCompanies} />
                                    : null}
                            </Tab>

                        </Tabs>

                    </div>

                </div>

            </div>

            <SidePannelBanner
                context={adContext}
                zoneId={372556}
                id="ssp-zone-372556"
            />

        </div>

    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetail);

function mapStateToProps(state) {

    return {
        token: localStorage.getItem('token'),
        user: localStorage.getItem('username'),
        showLimitError: state.limits.showLimitError
    }

}

function mapDispatchToProps(dispatch) {

    return {
        dispatchError: () => {
            dispatch(showLimitError())
        }
    }

}

function getCompanyData(companyId, token) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then(response => response.json())
}

function getDocuments(companyId, token) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}/documents`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then(response => response.json())
        .then(data => data.results)
}

function getFinancialData(companyId, token, isOneTime) {

    let url = ''
    if (isOneTime) {
        url = `${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}/financial_data?oneTime=true`
    } else {
        url = `${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}/financial_data`
    }

    return fetch(url, {
        method: 'get',
        headers: generateHeader(token),

    }).then(checkStatus)
        .then((response) => response.json())
};

function getSimilarCompaniesOverApi(companyId, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}/similar_companies`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then((response) => response.json())
}

function generateHeader(token) {

    return token ? { 'Authorization': `Bearer ${token}` } : {};

}

function getBusinessCategoriesOverApi(companyId, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/companies/${companyId}/business_categories`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then((response) => response.json())
}