import React from 'react';
import { checkStatus } from '../helpers';
import FinancialDataBlock from '../CompanyDetail/FinancialDataBlock';
import { connect } from 'react-redux';
import "./MunicipalityDetail.css";
import LimitationBanner from '../UiRestrictions/LimitationBanner';
import { Helmet } from "react-helmet";
import RobotsBanner from '../UiRestrictions/RobotsBanner';
import CustomDeletedIcon from '../Components/DeletedIcon';
import Skeleton from '@mui/material/Skeleton';
import { showLimitError } from '../Limiter/limiterActions';
import { Tabs, Tab } from 'react-bootstrap';
import InfoIcon from '@mui/icons-material/Info';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import { cropString } from '../helpers';
import { CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BigHorizontalBanner from '../Adverts/BigHorizontalBanner';
import ErrorLimitationBanner from '../Limiter/LimitsExceededBanner';
import { generateHeader } from '../helpers';
import MunicipalityDetailBlock from './MunicipalityDetailBlock';
import MunicipalityMetricsBlock from './MunicipalityMetricsBlock';
import SimilarMunicipalities from './SimilarMunicipalities';
import SimilarMunicipalitiesBlock from './SimilarMunicipalitiesBlock';
import { JoinLeft } from '@mui/icons-material';
import { useState } from 'react';
import { useEffect } from 'react';
import LeaderBoardBanner from '../Adverts/LeadBoardBanner';
import SidePannelBanner from '../Adverts/SidePannelBanner';
import { generateAdContext } from '../Adverts/adsHelper';
import MobilePopUp from '../Adverts/MobilePopUp';

const MunicipalityDetail = (props) => {

    const [municipality, setMunicipality] = useState(null);
    const [similarMunicipalities, setSimilarMunicipalities] = useState(null);
    const [financialData, setFinancialData] = useState(null);

    useEffect(() => {

        getMunicipalityData(props.match.params.id, props.token).then(municipality => setMunicipality(municipality))

        if (props.user) {
            getFinancialData(props.match.params.id, props.token)
                .then(financialData => setFinancialData(financialData))
                .catch(error => props.dispatchError())
        }
        else {
            getFinancialData(props.match.params.id, props.token, true)
                .then(financialData => setFinancialData(financialData))
                .catch(error => props.dispatchError())
        }

        getSimilarCompaniesOverApi(props.match.params.id, props.token).then((response) => setSimilarMunicipalities(response.results));

    }, [])

    const leaveCompanyDetail = () => {

        if (props.history.location.key) {
            props.history.goBack()
        }
        else {
            props.history.push("/")
        }
    }


    let metadata;
    let publicId;
    let title;
    let canonicalUrl;
    let slug;
    if (!municipality) {

        slug = window.location.href.split("/").pop()
        publicId = slug.includes('-') ? slug.split("-")[0] : "";
        canonicalUrl = window.location.href.replace("https://www.", "https://");
        title = slug.includes('-') ? slug.split("-").slice(1).map(name => name[0].toUpperCase() + name.slice(1)).join(" ") : "";

        title = title.replace(/ Sro$/, ' s.r.o.')
        title = title.replace(/ As$/, ' a.s.')

        const generatedContent = publicId ? `${title ? title + " ; " : ''}IČO:${publicId} ; URL: ${canonicalUrl}.` : `Detail municipality obsahující učetní uzávěrky. URL: ${canonicalUrl}.`

        metadata = <Helmet>
            <title>{title}</title>
            <meta name="description" content={generatedContent} />
            <meta name="og:description" content={generatedContent} />
            <meta name="og:title" content={title} />
            <meta property="og:type" content="website" />
            <link href={canonicalUrl} rel="canonical"></link>
            {publicId ? null : <meta name="robots" content="noindex" />}
        </Helmet>
    } else {
        canonicalUrl = window.location.href.replace("https://www.", "https://");
        const generatedContent = `Název: ${municipality.name} ; IČO: ${municipality.public_id} ; Adresa: ${municipality.address} ; URL: ${canonicalUrl}.`
        title = `${municipality.name} - IČO:${municipality.public_id}, ${cropString(municipality.region, 30)}`
        slug = window.location.href.split("/").pop()
        publicId = slug.includes('-') ? slug.split("-")[0] : "";

        metadata = <Helmet>
            <title>{title}</title>
            <meta name="description" content={generatedContent} />
            <meta name="og:description" content={generatedContent} />
            <meta name="og:title" content={title} />
            <meta property="og:type" content="website" />
            <link href={canonicalUrl} rel="canonical"></link>
            {publicId ? null : <meta name="robots" content="noindex" />}
        </Helmet>
    }

    const [adContext] = useState(generateAdContext(window.location.pathname, window.location.href.split("/").pop()));

    return (

        <div className="mainAndAdsContent">

            <SidePannelBanner
                context={adContext}
                zoneId={372553}
                id="ssp-zone-372553"
            />

            <div className='municipalityDetailMain'>

                <LeaderBoardBanner
                    context={adContext}
                    zoneId={372550}
                    id="ssp-zone-372550"
                />

                <div className='companyDetailMain'>

                    <div className="verticalNavigation">
                        <div className="navigationBackDiv" onClick={leaveCompanyDetail}>
                            <ArrowBackIcon fontSize="large" />
                        </div>
                    </div>
                    {metadata}

                    <div className="companyDetailRight">
                        <LimitationBanner />

                        {props.showLimitError ? <ErrorLimitationBanner /> : null}

                        {municipality ?
                            <div className='companyHeading'>
                                <div className='companyNameDiv'>
                                    {municipality.deleted_at ?
                                        <CustomDeletedIcon locationType="detail" deletedAt={municipality.deleted_at} />
                                        : null}
                                    <h1>{municipality.name}</h1>
                                </div>
                                <RobotsBanner />
                            </div> : <div className="loadingPlaceholder">
                                <h1>{title}</h1>
                                <CircularProgress />
                            </div>
                        }

                        <Tabs
                            defaultActiveKey="detail"
                            id="uncontrolled-tab-example"
                            className="mb-3 tabsMenu"
                            mountOnEnter={true}
                            unmountOnExit={true}
                        >

                            <Tab eventKey="detail" title={<span><InfoIcon style={{ color: `var(--outline)` }} /> Detail</span>}>

                                {municipality ?
                                    <MunicipalityDetailBlock
                                        name={municipality.name}
                                        id={municipality.public_id}
                                        region={municipality.region}
                                        population={municipality.population}
                                        url={canonicalUrl}
                                        slug={municipality.web_slug}
                                        user={props.user}
                                    />
                                    : <div>
                                        <MunicipalityDetailBlock
                                            name={title}
                                            id={publicId}
                                            url={canonicalUrl}
                                            slug={slug}
                                        />
                                        <div className='documentsSkeleton'>
                                            <Skeleton variant="rounded" height={300} />
                                        </div>
                                    </div>}

                                {municipality && financialData ?
                                    <MunicipalityMetricsBlock
                                        yearData={financialData}
                                        population={municipality.population}
                                        user={props.user} />
                                    : <div className='documentsSkeleton'>
                                        <Skeleton variant="rounded" height={300} />
                                    </div>}

                                <BigHorizontalBanner
                                    context={adContext}
                                    zoneId={355933}
                                    id="ssp-zone-355933"
                                />

                                {similarMunicipalities ?
                                    <SimilarMunicipalitiesBlock municipalities={similarMunicipalities.filter(c => c.public_id != publicId)} /> :
                                    <div className='documentsSkeleton'>
                                        <Skeleton variant="rounded" height={300} />
                                    </div>
                                }

                            </Tab>

                            <Tab eventKey="financial_state" title={<span><PriceChangeIcon style={{ color: `var(--outline)` }} /> Finanční stav</span>}>

                                <MobilePopUp
                                    id="ssp-zone-373828"
                                    zoneId={373828}
                                />

                                {municipality ?

                                    <FinancialDataBlock
                                        isMunicipality={true}
                                        company={municipality.name}
                                        financialData={financialData}
                                        user={props.user}
                                        orDocumentsUrl={[]}
                                    /> : null}
                                <p>Data si můžete dohledat na webu ministerstva financí.</p>
                            </Tab>

                            <Tab eventKey="similar_entities" title={<span><JoinLeft style={{ color: `var(--outline)` }} /> Podobné municipality</span>}>

                                {municipality ?
                                    <SimilarMunicipalities
                                        companies={similarMunicipalities}
                                    />
                                    : null}
                            </Tab>
                        </Tabs>

                    </div>
                </div>
            </div>

            <SidePannelBanner
                context={adContext}
                zoneId={372556}
                id="ssp-zone-372556"
            />

        </div>
    )
}


export default connect(mapStateToProps, mapDispatchToProps)(MunicipalityDetail);

function mapStateToProps(state) {

    return {
        token: localStorage.getItem('token'),
        user: localStorage.getItem('username'),
        showLimitError: state.limits.showLimitError
    }

}

function mapDispatchToProps(dispatch) {

    return {
        dispatchError: () => {
            dispatch(showLimitError())
        }
    }

}

function getMunicipalityData(companyId, token) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/municipalities/${companyId}`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then(response => response.json())
}

function getFinancialData(companyId, token, isOneTime) {

    let url = ''
    if (isOneTime) {
        url = `${process.env.REACT_APP_API_URL}/api/v1/municipalities/${companyId}/financial_data?oneTime=true`
    } else {
        url = `${process.env.REACT_APP_API_URL}/api/v1/municipalities/${companyId}/financial_data`
    }

    return fetch(url, {
        method: 'get',
        headers: generateHeader(token),

    }).then(checkStatus)
        .then((response) => response.json())
};

function getSimilarCompaniesOverApi(companyId, token) {
    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/municipalities/${companyId}/similar_municipalities`, {
        method: 'get',
        headers: generateHeader(token)
    }).then(checkStatus)
        .then((response) => response.json())
}