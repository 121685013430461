
import './BigHorizontalBanner.css';
import { useEffect } from 'react';
import { generateAdContext } from './adsHelper';
import { useState } from 'react';

const BigHorizontalBanner = (props) => {

    const [context] = useState(props.context ? props.context : generateAdContext(window.location.pathname, window.location.href.split("/").pop()));

    const isMobile = window.innerWidth < 720;

    useEffect(() => {
        if (context["source"] === 'seznam') {
            initAds()
        }
    }, [])

    const initAds = () => {

        const zones = [
            {
                "zoneId": props.zoneId,
                "id": props.id,
                "width": isMobile ? 300 : 970,
                "height": isMobile ? 250 : 310
            }
        ]

        setTimeout(function () {
            window["sssp"].setPageViewId()
            window["sssp"].getAds(zones)
        }, 0);
    }

    if (props.mobileOnly) {

        if (isMobile) {
            return (
                <div className="smallHorizontalBanner">
                    {context['source'] === 'seznam' ?
                        <div id={props.id} className='ad-content'></div> :
                        <iframe style={{ backgroundColor: context['bgColor'] }} width={isMobile ? 300 : 970} height={isMobile ? 250 : 310} src={`/ads/${context["path"]}/${isMobile ? 300 : 970}x${isMobile ? 250 : 310}.png`} />
                    }
                </div>
            )
        }
        else {
            return null
        }

    }

    return (
        <div className={isMobile ? "smallHorizontalBanner" : "bigHorizontalBanner"}>
            {context['source'] === 'seznam' ?
                <div id={props.id} className='ad-content'></div> :
                <iframe style={{ backgroundColor: context['bgColor'] }} width={isMobile ? 300 : 970} height={isMobile ? 250 : 310} src={`/ads/${context["path"]}/${isMobile ? 300 : 970}x${isMobile ? 250 : 310}.png`} />
            }
        </div>
    )

}

export default BigHorizontalBanner;