import React from "react";
import SearchBlock from "./SearchBlock";
import SearchResults from "./SearchResults";
import { connect } from "react-redux";
import SearchGuide from "./SearchGuide";
import { searchCompany, companySearchDone } from './state/searchActions';
import { checkStatus } from "../helpers";
import { withRouter } from "react-router";
import { generateHeader } from '../helpers';
import RobotsBanner from "../UiRestrictions/RobotsBanner";
import { Helmet } from 'react-helmet';
import "./CompanySearch.css";
import SidePannelBanner from "../Adverts/SidePannelBanner";


class CompanySearch extends React.Component {

    render() {

        return (

            <div className="mainAndAdsContent">

                <SidePannelBanner
                    zoneId={372553}
                    id="ssp-zone-372553"
                />

                <div className="searchMenuMain">

                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Finanční výkazy firem</title>
                        <meta name="description" content="financni-vykazy.cz je platforma pro vyhledavání finančních výkazů firem." />
                        <meta name="og:description" content="financni-vykazy.cz je platforma pro vyhledavání finančních výkazů firem." />
                        <meta name="og:title" content="Finanční výkazy" />
                        <meta property="og:type" content="website" />
                        <link href={window.location.href} rel="canonical"></link>

                    </Helmet>
                    <div className="robotsBanner">
                        <RobotsBanner />
                    </div>
                    <SearchBlock
                        searchTerm={this.props.searchTerm}
                        page={this.props.page}
                        pageSize={this.props.pageSize}
                        companiesTotalCount={this.props.companiesTotalCount}
                        searchSubmit={this.props.searchSubmit}
                        initiateSearch={this.props.initiateSearch}
                        history={this.props.history}
                        token={this.props.token}
                    />
                    {(!this.props.searchTerm || this.props.searchTerm === "") ? <SearchGuide /> :
                        this.props.companiesTotalCount === 0 && !this.props.isLoading ?
                            <p>{this.props.searchTerm.trim().length < 2 ? "Vyhledávaný termín musí obsahovat minimálně 2 znaky bez mezer." : "Pro zadaný text nebyl nalezen žádný výsledek."}</p> :
                            <SearchResults
                                searchTerm={this.props.searchTerm}
                                page={this.props.page}
                                pageSize={this.props.pageSize}
                                searchSubmit={this.props.searchSubmit}
                                initiateSearch={this.props.initiateSearch}
                                history={this.props.history}
                                companies={this.props.companies}
                                isLoading={this.props.isLoading}
                                companiesTotalCount={this.props.companiesTotalCount}
                                companiesPageCount={this.props.companiesPageCount}
                                token={this.props.token}
                                cutOff={this.props.cutOff}
                                cutOffLimit={this.props.cutOffLimit}
                            />
                    }

                </div>

                <SidePannelBanner
                    zoneId={372556}
                    id="ssp-zone-372556"
                />

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanySearch));

function mapStateToProps(state) {

    const params = new URLSearchParams(window.location.search);

    const page = parseInt(params.get('page'));
    const pageSize = parseInt(params.get('page_size'));
    const searchTerm = params.get('query');


    return {
        companies: state.search.companies,
        isLoading: state.search.isLoading,
        pageSize: pageSize ? pageSize : state.search.pageSize,
        page: page ? page : state.search.page,
        searchTerm: searchTerm ? searchTerm : state.search.searchTerm,
        companiesTotalCount: state.search.companiesTotalCount,
        companiesPageCount: state.search.companiesPageCount,
        token: localStorage.getItem('token'),
        cutOff: state.search.cutOff,
        cutOffLimit: state.search.cutOffLimit
    }
};


function mapDispatchToProps(dispatch) {

    return {

        initiateSearch: (history, searchTerm, page, pageSize) => {
            history.push({ pathname: window.location.pathname, search: `?page=${page}&page_size=${pageSize}&query=${encodeURIComponent(searchTerm)}` });
            dispatch(searchCompany(searchTerm, page, pageSize));
        },

        searchSubmit: (searchTerm, page, pageSize, token, signal) => {

            if (searchTerm === "" || searchTerm.trim().length < 2) {
                dispatch(companySearchDone(searchTerm, [], 0, 0, null, null));
            }
            else {
                getCompanies(page, pageSize, searchTerm, token, signal).then(({ results, pagination }) => {
                    dispatch(companySearchDone(searchTerm, results, pagination.total, pagination.total_pages, pagination.cut_off, pagination.cut_off_limit));
                }
                );
            }

        }

    };
}

function getCompanies(page, pageSize, query, token, signal) {

    return fetch(`${process.env.REACT_APP_API_URL}/api/v1/companies?page=${page}&page_size=${pageSize}&query=${encodeURIComponent(query)}`, {
        method: 'get',
        headers: generateHeader(token),
        signal: signal
    }).then(checkStatus)
        .then((response) => response.json())
};
