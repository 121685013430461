
import { useEffect } from 'react';
import './MobilePopUpBanner.css';


const MobilePopUpBanner = (props) => {

    const isMobileWide = window.innerWidth >= 500;

    useEffect(() => {
        initAds()
    }, [])

    const initAds = () => {

        const zones = [
            {
                "zoneId": props.zoneId,
                "id": props.id,
                "width": isMobileWide ? 500 : 320,
                "height": isMobileWide ? 200 : 100,
                "options": {
                    "reloadCount": 0
                }
            }
        ]

        setTimeout(function () {
            window["sssp"].setPageViewId()
            window["sssp"].getAds(zones)
        }, 0);
    }

    return (
        <div id={props.id} className={`mobilePopUpAd ${isMobileWide ? 'mobilePopUpAdBig' : 'mobilePopUpAdSmall'}`}>
        </div>
    )
}

export default MobilePopUpBanner;