import React from 'react';
import { cropString } from '../helpers';
import { Link } from "react-router-dom";
import './SimilarCompaniesBlock.css';

class SimilarCompaniesBlock extends React.Component {

    render() {

        if(!this.props.companies || this.props.companies.length == 0){
            return null;
        }

        return (
            <div className="metricsBlock">
                <h2>Podobné subjekty</h2>
                <table className="similarCompaniesTable">
                    <thead>
                        <tr>
                            <th>Název</th>
                            <th className='legalFormTd'>Právní forma</th>
                            <th>Adresa</th>
                            <th>Základní kapitál</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.companies.map(company =>
                            <tr>
                                <td>{cropString(company.name)}</td>
                                <td  className='legalFormTd'>{cropString(company.legal_form)}</td>
                                <td>{cropString(company.address)}</td>
                                <td>{cropString(company.equity_capital)}</td>
                                <td className="linkButton">
                                    <Link target="_blank" to={`/firmy/${company.url.split("/").pop()}/${company.web_slug}`}>Otevřít</Link>
                                    </td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
        )
    }

}

export default SimilarCompaniesBlock;