import React from "react";
import SimilarMunicipalityTile from "./SimilarMunicipalityTile";
import './SimilarMunicipalities.css';
import BigHorizontalBanner from "../Adverts/BigHorizontalBanner";


const SimilarMunicipalities = (props) => {

    if (!props.companies) {
        return (<p>Načítá se</p>)
    } else {
        return (

            <div>

                <div className="similarCompanies">

                    {props.companies.map(c => <SimilarMunicipalityTile name={c.name}
                        region={c.region}
                        population={c.population}
                        web_slug={c.web_slug}
                        url={c.url}
                    />)}
                </div></div>)
    }
}


export default SimilarMunicipalities;