
const advertsList = [
    {
        "path": "Ksk",
        "web": "https://www.ksk-belt.cz/",
        prob: 0.4,
        "bgColor": "aliceblue"
    },
    //{
    //    "path": "Destilery", "web": "http://www.palenicemalenovice.cz/",
    //    prob: 0.05,
    //},
    {
        "path": "Physiohub",
        "web": "https://www.physiohub.cz/",
        prob: 0.2,
        "bgColor": "antiquewhite"
    },
    {
        "path": "Offiseek",
        "web": "https://www.offiseek.cz/",
        prob: 0.4,
        "bgColor": "#e6fdbc"
    }
]

export default advertsList;