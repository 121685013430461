import react from "react";
import './DocumentsBlock.css';
import { Link } from "react-router-dom";
import OrLink from "./OrLink";

class DocumentsBlock extends react.Component {

    renderDocuments() {

        if (this.props.documents && this.props.documents.length > 0) {

            return (
                <div>

                    <table className="detailsTable">
                        <thead>
                            <tr>
                                <th>Rok</th>
                                <th>Dokumenty</th>
                                <th>Odkaz</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.documents.sort((a, b) => a.year < b.year ? 1 : -1).map((document, idx) => (
                                <tr key={idx}>
                                    <td>{document.year}</td>
                                    <td><ul className="yearDocuments">{document.metadata.sources.map(d => <li>{d.or_description}</li>)}</ul></td>
                                    <td>
                                        <Link className="documentLink documentLinkPdf" to={`${new URL(document.url.replace("/api/v1/documents", "/dokumenty")).pathname}`}>PDF</Link>
                                        <Link className="documentLink documentLinkXlsx" to={`${new URL(document.url.replace("/api/v1/documents", "/dokumenty")).pathname}`}>XLSX</Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>

            )

        }

        else {
            return (
                <div>
                    <div className="missingDocumentsDiv">

                        <p>Pro tuto firmu nebyly nalezeny žádné dokumenty s finančními výkazy</p>
                        <p className="termsDiv">Zpracováváme jen dokumenty od roku 2017.</p>

                    </div>

                </div>

            )
        }

    }

    render() {



        return (
            <div className="documentsBlockDiv">
                {this.renderDocuments()}
                {this.props.orDocumentsUrl ? <OrLink label="Odkaz na Obchodní rejstřík" url={this.props.orDocumentsUrl} /> : null}
            </div>
        )

    }

}

export default DocumentsBlock;