
import { useEffect } from 'react';
import './SidePannelBanner.css';
import { generateAdContext } from './adsHelper';
import { useState } from 'react';

const SidePannelBanner = (props) => {

    const [context] = useState(props.context ? props.context : generateAdContext(window.location.pathname, window.location.href.split("/").pop()));

    const isMobile = window.innerWidth < 720;
    const isSmall = window.innerWidth > 720 && window.innerWidth < 1500;

    useEffect(() => {
        if (!isMobile) {
            if (context["source"] === 'seznam') {
                initAds()
            }
        }
    }, [])

    const initAds = () => {

        const zones = [
            {
                "zoneId": props.zoneId,
                "id": props.id,
                "width": isSmall ? 160 : 300,
                "height": 600
            }
        ]

        setTimeout(function () {
            window["sssp"].setPageViewId()
            window["sssp"].getAds(zones)
        }, 0);
    }

    if (isMobile) {
        return null;
    }

    return (
        <div className={`${isSmall ? "smallSidePannelBanner" : "bigSidePannelBanner"}`}>
            {context['source'] === 'seznam' ?
                <div id={props.id} className='ad-content'></div> :
                <iframe width={isSmall ? 160 : 300} height={600} src={`/ads/${context["path"]}/${isSmall ? 160 : 300}x600.png`} />
            }
        </div>
    )

}

export default SidePannelBanner;