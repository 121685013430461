import advertsList from "./advertsList";
import { Helmet } from "react-helmet";
import './AdvertImage.css';

const AdvertImage = (props) => {

    const topic = props.match.params.topic;
    const context = findAdvertByTopic(advertsList, topic);

    const imageName = props.match.params.image_name;
    const [width, height] = imageName.replace(".png", "").split("x");

    const metadata = <Helmet>
        <meta name="robots" content="noindex" />
    </Helmet>

    return (
        <div>
            {metadata}
            <a href={context["web"]} target="_blank">
                <span className='advert-label'>Reklama</span>
                <img className='advert-image' style={{ backgroundColor: context["bgColor"] }} src={`/images/ads/${topic}/${width}x${height}.png`}></img>
            </a>
        </div>
    )


}

export default AdvertImage;

function findAdvertByTopic(advertsList, topic) {

    for (let i = 0; i < advertsList.length; i++) {
        if (advertsList[i]["path"] === topic) {
            return advertsList[i]
        }
    }
    return null;
}