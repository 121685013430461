
function searchCompany(searchTerm, page, pageSize){

    return {
        type: "SEARCH_INITIALIZE",
        isLoading: true,
        searchTerm: searchTerm,
        page: page,
        pageSize: pageSize,
        cutOff: null,
        cutOffLimit: null

    }

}

function companySearchDone(searchTerm, companies, totalCount, totalPages, cutOff, cutOffLimit){

    return {
        type: "SEARCH_DONE",
        isLoading: false,
        companies: companies,
        companiesTotalCount: totalCount,
        companiesPageCount: totalPages,
        searchTerm: searchTerm,
        cutOff: cutOff,
        cutOffLimit: cutOffLimit

    }

}

export {searchCompany, companySearchDone};