
import { useEffect, useState } from 'react';
import './MobilePopUp.css';
import closeButtonSvg from "./full-screen-close-button-square.svg";
import MobilePopUpBanner from './MobilePopUpBanner';


const MobilePopUp = (props) => {

    const [show, setShow] = useState(false);

    const isMobile = window.innerWidth < 720 && window.innerWidth >= 320;

    useEffect(() => {

        if (isMobile) {
            setTimeout(function () {
                setShow(true)
            }, 10000);
        }

    }, [])

    if (!isMobile || !show) {
        return null
    }

    else {
        return (
            <div className="mobilePopUp">

                <img className='mobilePopUpClose' src={closeButtonSvg} onClick={() => setShow(false)} alt=""></img>
                <MobilePopUpBanner
                    id={props.id}
                    zoneId={props.zoneId}
                />

            </div>
        )
    }
}

export default MobilePopUp;
