
import advertsList from "./advertsList";
import Rand from 'rand-seed';

function generateAdContext(url, webSlug) {

    const myVsSeznamAds = new Rand(url).next();

    if (myVsSeznamAds >= 0.3) {
        return { source: "seznam" }
    }
    else {
        const advertIndex = generateIndexBasedOnProbability(advertsList.map(a => a.prob), webSlug);
        return { ...advertsList[advertIndex], "source": "custom" }

    }

}

function generateIndexBasedOnProbability(probabilities, url) {
    const randomNumber = new Rand(url).next();
    let cumulativeProb = 0;
    for (let i = 0; i < probabilities.length; i++) {
        cumulativeProb += probabilities[i]
        if (randomNumber <= cumulativeProb) {
            return i;
        }
    }
    return probabilities.length - 1
}

export { generateAdContext }